import es from "date-fns/locale/es";
import React, {useEffect, useState} from "react";
import Scheduler from "@aldabil/react-scheduler";
import {parseISO} from "date-fns";
import ClassroomsService from "../../services/ClassroomService";
import TutoriasEventsService from "../../services/TutoriasService"
import {WEEK, TRANSLATIONS, FIELDS} from "./TutoriasConfig";
import {Skeleton, Typography} from "@mui/material";
import MainCard from "../genericui/cards/MainCard";
import TutoriaEventCustomRenderer from "./TutoriaEventCustomRenderer";
import TutoriaCustomEditor from "./TutoriaCustomEditor";
import StudentsService from "../../services/StudentsService";
import {useOutletContext} from "react-router-dom";
import SettingsService from "../../services/SettingsService";

export default function Tutorias() {
    const context = useOutletContext();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [settings, setSettings] = useState({});
    const [week, setWeek] = useState(WEEK);
    const [classrooms, setClassrooms] = useState([]);
    const [students, setStudents] = useState([]);

    useEffect(() => {
        if (isFirstLoad) {
            ClassroomsService.getCreatedClassrooms().then((classrooms) => {
                    setClassrooms(classrooms.data);
                    setIsFirstLoad(false);
                },
                _ => {
                    context.handleAPIError("No se han podido cargar tus clases. Inténtalo de nuevo más tarde.");
                });
            StudentsService.getStudentsByCreator().then((students) => {
                    setStudents(students.data);
                },
                _ => {
                    context.handleAPIError("No se han podido cargar tus alumnos. Inténtalo de nuevo más tarde.");
                });
            SettingsService.getCalendarSettings().then((response) => {
                    setSettings(response.data);
                    const weekCopy = {...week};
                    weekCopy.step = response.data.tutorias_step;
                    weekCopy.startHour = response.data.tutorias_start_hour;
                    weekCopy.endHour = response.data.tutorias_end_hour;
                    setWeek(weekCopy);
                },
                _ => {
                    context.handleAPIError("No se han podido cargar tus datos. Por favor, inténtalo más tarde.");
                }
            ).finally(() => setIsFirstLoad(false))
        }
    }, [isFirstLoad, context, week]);

    function getTutoriasEvents(params) {
        return TutoriasEventsService.getExistingTutoriasEvents(params.start.toISOString(), params.end.toISOString())
            .then((response) => {
                    const newData = response.data

                    newData.forEach((element) => {
                            const classroomFound = classrooms.find(({id}) => id === element.classroom_id);
                            const studentFound = students.find(({id}) => id === element.student_id);
                            element.event_id = element.id;
                            element.classroom_name = classroomFound.name;
                            element.student_name = studentFound.name;
                            element.start = new Date(parseISO(element.start));
                            element.end = new Date(parseISO(element.end));
                        }
                    );
                    return newData;
                },
                _ => {
                    context.handleAPIError("No se han podido cargar tus eventos. Inténtalo de nuevo más tarde.");
                });
    }

    function onEventDrop(droppedOn, updatedEvent, originalEvent) {
        return TutoriasEventsService.updateTutoriaEvent(updatedEvent, originalEvent.event_id).then(
            () => {
                const event = updatedEvent;
                event.event_id = updatedEvent.id;
                return event;
            });
    }

    function customEditor(scheduler) {
        return <TutoriaCustomEditor
            scheduler={scheduler}
            classrooms={classrooms}
            students={students}
            handleAPIError={context.handleAPIError}
        />;
    }

    function onDelete(deletedId) {
        return TutoriasEventsService.deleteTutoriaEvent(deletedId).then(
            () => deletedId,
            _ => context.handleAPIError("No se ha podido borrar el evento. Inténtalo de nuevo más tarde.")
        );
    }

    function viewerExtraComponent(fields, event) {
        return (
            <>
                <Typography>Clase: {event.classroom_name}</Typography>
                <Typography>Solicitado por: {event.requested_by}</Typography>
                {event.body ? _getBodyDiv(event.body) : null}
            </>
        );
    }

    function _getBodyDiv(body) {
        return (
            <>
                <Typography variant="h6">Contenido:</Typography>
                <div dangerouslySetInnerHTML={{__html: body}}/>
            </>
        );
    }

    if (isFirstLoad) {
        return (<Skeleton variant="rectangular" height={"1000px"}/>);
    }
    return <MainCard title="👨‍👩‍👧‍👦 Tutorías" sx={{overflowY: 'auto',}}>
        <Scheduler
            week={week}
            fields={FIELDS}
            translations={TRANSLATIONS}
            hourFormat={"24"}
            locale={es}
            customEditor={customEditor}
            onEventDrop={onEventDrop}
            onDelete={onDelete}
            getRemoteEvents={getTutoriasEvents}
            eventRenderer={TutoriaEventCustomRenderer}
            viewerExtraComponent={viewerExtraComponent}
            view={settings.tutorias_default_mode}
        />
    </MainCard>
}