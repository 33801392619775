import {Card, Grid, Skeleton} from "@mui/material";
import IACard from "./IACard";
import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import AIService from "../../services/AIService";

const PREDEFINED_IAS = [
    {
        title: "Situaciones de Aprendizaje",
        description: "Prepara tus clases y obtén ideas originales adaptadas a tus alumnos.",
        img: "/static/ia/situaciones-aprendizaje.png",
        url: "/ia/sdas",
        badgeContent: "¡Popular!",
    },
    {
        title: "Asistente Virtual",
        description: "Tu propio asistente virtual entrenado con la normativa en educación actual.",
        img: "/static/ia/chatbot.png",
        url: "/ia/chat",
        badgeContent: "¡Nueva!",
    },
    {
        title: "Rúbricas de Evaluación",
        description: "Rúbricas para cualquier nivel y materia en solo unos segundos.",
        img: "/static/ia/rubricas.png",
        url: "/ia/rubricas",
    },
    {
        title: "Ejercicios",
        description: "Genera ejercicios y actividades con sus respuestas a partir de tu temario.",
        img: "/static/ia/ejercicios.png",
        url: "/ia/ejercicios",
    },
    {
        title: "Comprobador de Currículo",
        description: "Proporciona tus actividades y te decimos qué currículo adaptado a la LOMLOE trabajarás.",
        img: "/static/ia/comprobador-lomloe.png",
        url: "/ia/comprobador-lomloe",
    },
]

const IADashboard = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [ias, setIas] = React.useState([]);

    useEffect(() => {
        AIService.getTools().then((ias) => {
            const parsedIas = ias.data.map((ia) => {
                const badge = ia.is_new ? "¡Nueva!" : ia.is_hot ? "¡Popular": null;
                return {
                    title: ia.title,
                    description: ia.description,
                    img: ia.icon,
                    url: `/ia/${ia.slug}`,
                    badgeContent: badge,
                }
            })
            setIas(parsedIas);
            setIsLoading(false);
        });
    }, [])

    return <>
        <Card variant="outlined" sx={{p: 4, backgroundColor: '#FFFFFF80', color: 'text.primary', textAlign: 'center'}}>
            <Typography variant="h4" sx={{fontWeight: 'bold'}}>🪄 Catálogo de Inteligencia Artificial</Typography>
            <Typography>¡Cada mes, añadimos una nueva inteligencia artificial a nuestro catálogo!</Typography>
        </Card>
        <Grid container spacing={4} sx={{py: 4}}>
            {isLoading ? <Grid item xs={12} md={6} key="loading"><Skeleton component={Card} variant="rectangular" width="100%" height="100%"/></Grid> :
                <>{PREDEFINED_IAS.map((item) =>
                <Grid item xs={12} md={6} key={item.title}>
                    <IACard key={item.title} title={item.title} description={item.description} img={item.img}
                            url={item.url} badgeContent={item.badgeContent}/>
                </Grid>
            )} {ias.map((item) => <Grid item xs={12} md={6} key={item.title}>
                    <IACard key={item.title} title={item.title} description={item.description} img={item.img}
                            url={item.url} badgeContent={item.badgeContent}/>
                </Grid>)}</> }
        </Grid>
    </>
}

export default IADashboard;