import React from "react";
import Card from "@mui/material/Card";
import {Badge, CardActionArea, CardContent, CardMedia} from "@mui/material";
import Typography from "@mui/material/Typography";

const IACard = (props) => {
    const {img, title, description, url, badgeContent} = props;
    return (
        <Badge badgeContent={badgeContent} color="primary" overlap="circular">
            <Card sx={{width: '100%', height: '151px'}}>
                <CardActionArea href={url}
                                sx={{display: 'flex', width: '100%', justifyContent: 'left'}}>
                    <CardMedia
                        component="img"
                        sx={{width: 151, height: 151}}
                        image={img}
                        alt={title}
                    />
                    <CardContent sx={{flex: '1', height: '151px', width: '100%'}}>
                        <Typography component="div" variant="h6" sx={{fontWeight: 'bold'}}>
                            {title}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical'}}
                        >
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Badge>
    );
}

export default IACard;