import React, {useState, useEffect} from "react";
import {
    Autocomplete,
    Card,
    Checkbox,
    createFilterOptions,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    Select,
    Switch
} from "@mui/material";
import {useOutletContext} from "react-router-dom";
import AIService from "../../../services/AIService";
import TextField from "@mui/material/TextField";
import {LoadingButton} from "@mui/lab";
import SubscriptionPopup from "../../subscription/popup";
import SettingsService from "../../../services/SettingsService";
import RubricDetails from "./RubricDetails";
import MenuItem from "@mui/material/MenuItem";
import RubricTable from "./RubricTable";
import Typography from "@mui/material/Typography";
import Progress from "../../genericui/feedback/Progress";
import IAFeedback from "../feedback/IAFeedback";
import SaveIconTwoTone from "@mui/icons-material/SaveTwoTone";
import Button from "@mui/material/Button";
import ArticleIconTwoTone from '@mui/icons-material/ArticleTwoTone';
import {COMUNIDADES, LEVELS} from "../../classrooms/utils";
import SubjectService from "../../../services/SubjectService";
import EvaluationService from "../../../services/EvaluationService";
import {
    CheckBoxTwoTone as CheckBox,
    CheckBoxOutlineBlankTwoTone as CheckBoxOutlineBlank
} from "@mui/icons-material";

const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

const RubricForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [output, setOutput] = useState("");
    const {handleAPIError, handleSuccessMessage} = useOutletContext();
    const [level, setLevel] = useState(null);
    const [subject, setSubject] = useState(null);
    const [nivelesDeLogro, setNivelesDeLogro] = useState(5);
    const [numeroCriterios, setNumeroCriterios] = useState(3);
    const [descripcion, setDescripcion] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [criterios, setCriterios] = useState(["", "", ""]);
    const [json, setJson] = useState(null);
    const [userWantsToAddCriterio, setUserWantsToAddCriterio] = useState(false);
    const [editedRubric, setEditedRubric] = useState(null);
    const [lomloeMode, setLomloeMode] = useState(false);
    const [region, setRegion] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [criterio, setCriterio] = useState("Criterios");
    const [curriculo, setCurriculo] = useState({});
    const [selectedCriterios, setSelectedCriterios] = useState([]);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (region?.id && level?.id && lomloeMode) {
            SubjectService.getLomloeSubjectsByRegionAndLevel(region.id, level.id)
                .then(response => setSubjects(response.data))
                .catch(() => setSubjects([]));
        }
    }, [region?.id, level?.id, lomloeMode]);

    const refreshCriterios = (newSubject) => {
        if (newSubject) {
            setSelectedCriterios([]);
            EvaluationService.getLomloeCriteriosBySubject(newSubject.id)
                .then(response => setCurriculo(response.data));
        }
    }

    const validateLomloeForm = () => {
        const errors = {};
        
        if (!region?.id) {
            errors.region = "Debes seleccionar una comunidad autónoma";
        }
        
        if (!level?.id) {
            errors.level = "Debes seleccionar un nivel";
        }
        
        if (!subject?.id) {
            errors.subject = "Debes seleccionar una asignatura";
        }
        
        if (selectedCriterios.length === 0) {
            errors.criterios = "Debes seleccionar al menos un criterio";
        }
        
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const validateRegularForm = () => {
        const errors = {};
        
        if (!level) {
            errors.level = "Debes especificar un nivel";
        }
        
        if (!subject) {
            errors.subject = "Debes especificar una asignatura";
        }
        
        if (userWantsToAddCriterio) {
            const criteriosErrors = [];
            criterios.forEach((criterio, index) => {
                if (!criterio.trim()) {
                    criteriosErrors[index] = `El criterio ${index + 1} es obligatorio`;
                }
            });
            if (criteriosErrors.length > 0) {
                errors.criterios = criteriosErrors;
            }
        }
        
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    function sendForm() {
        if (lomloeMode && !validateLomloeForm() || !lomloeMode && !validateRegularForm()) {
            return;
        }
        
        setLoading(true);
        let criteriosList = null;
        if (curriculo[criterio]) {
            criteriosList = curriculo[criterio]
                .filter(element => selectedCriterios.includes(element.id))
                .map(element => element.label + " - " + element.title);
        }

        const formData = {
            "level": level?.name || level,
            "subject": subject?.name || subject,
            "nivelesDeLogro": nivelesDeLogro,
            "numeroCriterios": criteriosList ? criteriosList.length : numeroCriterios,
            "description": descripcion,
            "json": true,
            "criterios": criteriosList ? criteriosList.join(", ") : (criterios[0] !== "" ? criterios.join(", ") : undefined)
        }

        generateContent(formData);
    }

    function generateContent(formData) {
        setJson(null);
        setOutput('');
        setEditedRubric(null);
        SettingsService.getProfileInfo().then(response => {
            if (!response.data.is_subscription_active) {
                setIsPopupOpen(true);
            }
        })
        AIService.generateRubric(formData).then(response => {
                try {
                    const jsonResponse = JSON.parse(response.data.text);
                    setJson(jsonResponse);
                    setEditedRubric(jsonResponse);
                } catch (error) {
                    setOutput(response.data.text);
                }
                setLoading(false);
            },
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        ).catch(error => {
            handleAPIError(error);
            setLoading(false);
        }).finally(() => setLoading(false));
    }

    function handleNumeroCriteriosChange(e) {
        const oldNumeroCriterios = numeroCriterios;
        const newNumeroCriterios = e.target.value;

        setNumeroCriterios(newNumeroCriterios);

        if (newNumeroCriterios < oldNumeroCriterios) {
            setCriterios(criterios.slice(0, newNumeroCriterios));
        } else if (newNumeroCriterios > oldNumeroCriterios) {
            const newCriterios = [...criterios];
            for (let i = oldNumeroCriterios; i < newNumeroCriterios; i++) {
                newCriterios.push("");
            }
            setCriterios(newCriterios);
        }
    }

    function handleCriterioChange(e, criterio) {
        const newCriterios = [...criterios];
        newCriterios[criterio - 1] = e.target.value;
        setCriterios(newCriterios);
    }

    function handleRubricChange(newRubric) {
        setEditedRubric(newRubric);
    }

    function saveCurrentRubric() {
        setLoading(true);
        const rubricToSend = {
            "level": level,
            "subject": subject,
            "json": editedRubric || json
        }
        AIService.saveRubric(rubricToSend).then(
            response => {
                handleSuccessMessage("¡Rúbrica guardada correctamente!")
            },
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        ).finally(() => {
            setLoading(false)
        });
    }

    const handleRegionChange = (e, value) => {
        setRegion(value);
        setLevel(null);
        setSubject(null);
        setCurriculo({});
        setSelectedCriterios([]);
        setFormErrors({...formErrors, region: undefined});
    };

    const handleLevelChange = (e, value) => {
        setLevel(value);
        setSubject(null);
        setCurriculo({});
        setSelectedCriterios([]);
        setFormErrors({...formErrors, level: undefined});
    };

    const resetForm = () => {
        setLevel(null);
        setSubject(null);
        setNivelesDeLogro(5);
        setNumeroCriterios(3);
        setDescripcion("");
        setCriterios(["", "", ""]);
        setJson(null);
        setOutput("");
        setUserWantsToAddCriterio(false);
        setEditedRubric(null);
        setRegion(null);
        setSubjects([]);
        setCriterio("Criterios");
        setCurriculo({});
        setSelectedCriterios([]);
        setFormErrors({});
    };

    const handleModeChange = () => {
        resetForm();
        setLomloeMode(!lomloeMode);
    };

    const handleCriteriosSwitch = () => {
        setUserWantsToAddCriterio(!userWantsToAddCriterio);
    };

    return <>
        {isPopupOpen && <SubscriptionPopup onClose={() => setIsPopupOpen(false)}/>}
        <Grid container spacing={2} sx={{p: 2, display: 'flex'}}>
            <Grid item xs={12}>
                <Card variant="outlined"
                      sx={{p: 1, backgroundColor: '#FFFFFF80', color: 'text.primary', textAlign: 'center'}}>
                    <Typography variant="h4">Generador de Rúbricas con IA</Typography>
                    <Typography>Introduce los datos de tu clase y tu actividad y generaremos una Rúbrica totalmente
                        perzonalizada 😊</Typography>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Button 
                    fullWidth 
                    variant="contained" 
                    color="info" 
                    href="/ia/mis-rubricas" 
                    startIcon={<ArticleIconTwoTone/>}
                >
                    Ver mis Rúbricas guardadas
                </Button>
            </Grid>
            <Grid item xs={12} sx={{mx: 2}}>
                <FormGroup>
                    <FormControlLabel 
                        control={
                            <Switch 
                                checked={lomloeMode}
                                onChange={handleModeChange}
                            />
                        }
                        label="Modo LOMLOE"
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <Typography>Introduce los datos de tu clase:</Typography>
            </Grid>
            {!lomloeMode ? (
                <>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            fullWidth 
                            required
                            label="Nivel" 
                            variant="outlined" 
                            placeholder="1º Primaria"
                            onChange={(e) => {
                                setLevel(e.target.value);
                                setFormErrors({...formErrors, level: undefined});
                            }}
                            error={!!formErrors.level}
                            helperText={formErrors.level}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            fullWidth 
                            required
                            label="Área" 
                            variant="outlined" 
                            placeholder="Matemáticas"
                            onChange={(e) => {
                                setSubject(e.target.value);
                                setFormErrors({...formErrors, subject: undefined});
                            }}
                            error={!!formErrors.subject}
                            helperText={formErrors.subject}
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            id="lomloe_region"
                            options={COMUNIDADES}
                            getOptionLabel={(option) => option?.name || ''}
                            onChange={handleRegionChange}
                            value={region}
                            isOptionEqualToValue={(option, value) => 
                                option?.id === value?.id
                            }
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    required 
                                    label="Comunidad Autónoma"
                                    variant="outlined"
                                    fullWidth
                                    error={!!formErrors.region}
                                    helperText={formErrors.region}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            id="lomloe_level"
                            options={LEVELS}
                            disabled={!region}
                            value={level}
                            getOptionLabel={(option) => option?.name || ''}
                            onChange={handleLevelChange}
                            isOptionEqualToValue={(option, value) => 
                                option?.id === value?.id
                            }
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    required 
                                    label="Nivel"
                                    variant="outlined"
                                    fullWidth
                                    error={!!formErrors.level}
                                    helperText={formErrors.level}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            id="lomloe_subject"
                            options={subjects}
                            value={subject}
                            disabled={!subjects || subjects.length === 0}
                            getOptionLabel={(option) => option?.name || ''}
                            isOptionEqualToValue={(option, value) => 
                                option?.id === value?.id
                            }
                            onChange={(e, value) => {
                                setSubject(value);
                                refreshCriterios(value);
                                setFormErrors({...formErrors, subject: undefined});
                            }}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    required 
                                    label="Asignatura"
                                    variant="outlined"
                                    fullWidth
                                    error={!!formErrors.subject}
                                    helperText={formErrors.subject}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="niveles-de-logro">Indicadores de logro (columnas)</InputLabel>
                    <Select
                        labelId="niveles-de-logro"
                        id="select-niveles"
                        value={nivelesDeLogro}
                        label="Indicadores de logro (columnas)"
                        onChange={(e) => setNivelesDeLogro(e.target.value)}
                    >
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
                </>
            )}
            <Grid item xs={12}>
                <TextField fullWidth label="Descripción de la actividad" multiline maxRows={4} minRows={2}
                           variant="outlined"
                           placeholder="Describe la actividad que quieres evaluar. Cuáles son los objetivos que se van a alcanzar, cómo se va a llevar a cabo, etc."
                           onChange={(e) => setDescripcion(e.target.value)}/>
            </Grid>
            {lomloeMode ? (
                <Grid container spacing={2} sx={{p: 2}}>
                    <Grid item xs={12}>
                        <Typography>Selecciona los criterios para tu rúbrica:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Select
                                value={criterio}
                                onChange={(event) => setCriterio(event.target.value)}
                            >
                                <MenuItem value={"Criterios"}>Criterios</MenuItem>
                                <MenuItem value={"Competencias Específicas"}>Competencias Específicas</MenuItem>
                                <MenuItem value={"Competencias Clave"}>Competencias Clave</MenuItem>
                                <MenuItem value={"Descriptores"}>Descriptores</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            freeSolo
                            fullWidth
                            disabled={!curriculo[criterio] || !region?.id || !level?.id || !subject?.id}
                            value={curriculo[criterio]?.filter(c => selectedCriterios.includes(c.id)) || []}
                            onChange={(event, values) => {
                                setSelectedCriterios(values.map(element => element.id));
                                setNumeroCriterios(values.length);
                                setFormErrors({...formErrors, criterios: undefined});
                            }}
                            disableCloseOnSelect
                            options={curriculo[criterio] || []}
                            getOptionLabel={(option) => `${option.label} - ${option.title}`}
                            filterOptions={createFilterOptions({
                                stringify: (option) => option.label + " " + option.title,
                                ignoreAccents: true,
                                ignoreCase: true,
                            })}
                            renderOption={(props, option, { selected }) => {
                                const { key, ...otherProps } = props;
                                return (
                                    <li key={key} {...otherProps}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        <Typography>
                                            <strong>{option.label}</strong>: {option.title}
                                        </Typography>
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={`Selecciona los ${criterio}`}
                                    placeholder={!region?.id || !level?.id || !subject?.id ? 
                                        "Primero selecciona Comunidad, Nivel y Asignatura" : 
                                        `Selecciona los ${criterio} para tu rúbrica`}
                                    helperText={selectedCriterios.length > 0 ? 
                                        `${selectedCriterios.length} ${criterio} seleccionados. Tu rúbrica tendrá ${nivelesDeLogro} columnas y ${numeroCriterios} filas.` : 
                                        formErrors.criterios || ""}
                                    error={!!formErrors.criterios}
                                    required
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="niveles-de-logro">Indicadores de logro (columnas)</InputLabel>
                    <Select
                        labelId="niveles-de-logro"
                        id="select-niveles"
                        value={nivelesDeLogro}
                        label="Indicadores de logro (columnas)"
                        onChange={(e) => setNivelesDeLogro(e.target.value)}
                    >
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Número de criterios</InputLabel>
                            <Select
                                value={numeroCriterios}
                                label="Número de criterios"
                                onChange={handleNumeroCriteriosChange}
                            >
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch 
                                        checked={userWantsToAddCriterio}
                                        onChange={handleCriteriosSwitch}
                                    />
                                }
                                label="Quiero proporcionar yo los criterios"
                            />
                        </FormGroup>
                    </Grid>
                    {userWantsToAddCriterio && Array.from({length: numeroCriterios}, (_, i) => i + 1).map(criterio => (
                        <Grid item xs={12} key={criterio}>
                            <TextField 
                                fullWidth 
                                required
                                label={`Criterio ${criterio}`} 
                                variant="outlined"
                                onChange={(e) => {
                                    handleCriterioChange(e, criterio);
                                    const newErrors = {...formErrors};
                                    if (newErrors.criterios) {
                                        newErrors.criterios[criterio - 1] = undefined;
                                        if (newErrors.criterios.every(err => !err)) {
                                            delete newErrors.criterios;
                                        }
                                        setFormErrors(newErrors);
                                    }
                                }}
                                error={!!(formErrors.criterios?.[criterio - 1])}
                                helperText={formErrors.criterios?.[criterio - 1]}
                            />
                        </Grid>
                    ))}
                </>
            )}
            <Grid item xs={12}>
                <LoadingButton fullWidth loading={loading} variant="contained" color="primary"
                               onClick={sendForm}>¡Generar rúbrica!</LoadingButton>
            </Grid>
            {loading && <Grid item xs={12}>
                <Progress/>
            </Grid>}
            <Grid item xs={12}>
                {output && <>
                    <RubricDetails rubric={output}/>
                    <IAFeedback tool="Rubric" input={{
                        "level": level,
                        "subject": subject,
                        "nivelesDeLogro": nivelesDeLogro,
                        "numeroCriterios": numeroCriterios,
                        "description": descripcion,
                        "json": true,
                    }} output={output}/>
                </>}
                {json && <>
                    <RubricTable 
                        rubric={editedRubric || json}
                        editable={true}
                        onChange={handleRubricChange}
                    />
                    <IAFeedback tool="Rubric" input={{
                        "level": level,
                        "subject": subject,
                        "nivelesDeLogro": nivelesDeLogro,
                        "numeroCriterios": numeroCriterios,
                        "description": descripcion,
                        "json": true,
                    }} output={json}/>
                    <Grid container spacing={2} sx={{mt: 2}}>
                        <Grid item xs={12}>
                            <LoadingButton 
                                fullWidth 
                                loading={loading} 
                                variant="contained" 
                                color="primary"
                                onClick={saveCurrentRubric} 
                                startIcon={<SaveIconTwoTone/>}
                            >
                                Guardar Rúbrica
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </>}
            </Grid>
        </Grid>
    </>
}

export default RubricForm;