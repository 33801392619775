import React, {useState} from "react";
import {Card, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, Select} from "@mui/material";
import {useOutletContext} from "react-router-dom";
import AIService from "../../../services/AIService";
import TextField from "@mui/material/TextField";
import {LoadingButton, ToggleButton, ToggleButtonGroup} from "@mui/lab";
import EjercicioDetails from "./EjercicioDetails";
import {MuiFileInput} from 'mui-file-input';
import {AttachFile, Close} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ArticleIcon from "@mui/icons-material/Article";
import SaveIcon from "@mui/icons-material/Save";
import SubscriptionPopup from "../../subscription/popup";
import SettingsService from "../../../services/SettingsService";
import Typography from "@mui/material/Typography";
import IAFeedback from "../feedback/IAFeedback";
import Progress from "../../genericui/feedback/Progress";

const EJERCICIOS_ORIGINALES = [
    "Caja de aprendizaje",
    "Cuestionario",
    "Cuestionario de investigación",
    "Dinámicas en grupos",
    "NO QUIERO PENSAR, PIENSA UNA IDEA ORIGINAL",
    "Escape room",
    "Juego educativo",
    "Investigación académica",
    "Presentación oral",
    "Video documental",
    "Gráfico informativo",
    "Programa de radio",
    "Libro digital",
    "Cómic",
    "Actuación",
    "Publicación",
    "Video instructivo",
    "Anuncio",
    "Guía",
    "Discusión",
    "Montaje",
    "Melodía",
    "Texto teatral",
    "Juego de interpretación",
    "Presentación multimedia",
    "Pintura mural",
]

const EjerciciosForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [output, setOutput] = useState("");
    const {handleAPIError, handleSuccessMessage} = useOutletContext();
    const [level, setLevel] = useState("");
    const [subject, setSubject] = useState("");
    const [respuestas, setRespuestas] = useState("");
    const [tipoEjercicio, setTipoEjercicio] = useState("Respuesta libre corta");
    const [numeroPreguntas, setNumeroPreguntas] = useState(5);
    const [otros, setOtros] = useState("");
    const [error, setError] = useState("");
    const [file, setFile] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [modo, setModo] = useState("Ejercicios Tradicionales");


    function sendForm() {
        setLoading(true);

        const formData = new FormData();
        formData.append('level', level);
        formData.append('subject', subject);
        formData.append('otros', otros);
        formData.append('file', file);
        formData.append('tipo', tipoEjercicio);
        formData.append('respuestas', respuestas);
        formData.append('modo', modo);
        formData.append('numero', numeroPreguntas);

        generateContent(formData);
    }

    function generateContent(formData) {
        SettingsService.getProfileInfo().then(response => {
            if (!response.data.is_subscription_active) {
                setIsPopupOpen(true);
            }
        })
        if (file) {
            AIService.generateEjercicio(formData).then(response => {
                    setOutput(response.data.text);
                    setLoading(false);
                },
                _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
            ).catch(error => {
                handleAPIError(error);
                setLoading(false);
            }).finally(() => setLoading(false));
        } else {
            setLoading(false);
            setError("Debes subir un archivo");
        }
    }

    function handleUpload(value) {
        if (value?.size > 10 * 1024 * 1024) {
            setError("El archivo es demasiado grande. Tamaño maximo: 10 MB");
        } else {
            setError("");
            setFile(value);
        }
    }

    function saveCurrentEjercicio() {
        setLoading(true);
        const ejercicioToSend = {
            "level": level,
            "subject": subject,
            "otros": otros,
            "markdown": output,
            "tipo": tipoEjercicio,
            "respuestas": respuestas,
            "numero": numeroPreguntas,
            "modo": modo,
        }
        AIService.saveEjercicio(ejercicioToSend).then(response => {
                handleSuccessMessage("¡Ejercicio guardado correctamente!")
            },
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        ).finally(() => {
            setLoading(false)
        });
    }

    return <>
        {isPopupOpen && <SubscriptionPopup onClose={() => setIsPopupOpen(false)}/>}
        <Grid container spacing={2} sx={{p: 2, display: 'flex'}}>
            <Grid item xs={12}>
                <Card variant="outlined"
                      sx={{p: 1, backgroundColor: '#FFFFFF80', color: 'text.primary', textAlign: 'center'}}>
                    <Typography variant="h4">Generador de ejercicios y actividades con IA</Typography>
                    <Typography>Introduce los detalles de tu clase y sube un archivo con el contenido didáctico y
                        generaremos ejercicios para ti 😊</Typography>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth variant="outlined" color="primary" href="/ia/mis-ejercicios"
                        startIcon={<ArticleIcon/>}>Ver
                    mis Ejercicios guardados</Button>
            </Grid>
            <Grid item xs={12}>
                <ToggleButtonGroup
                    fullWidth
                    label="Modo de generación"
                    color="primary"
                    value={modo}
                    exclusive
                    onChange={(e, value) => {
                        value === "Ejercicios dinámicos y originales" ? setTipoEjercicio(EJERCICIOS_ORIGINALES[0]) : setTipoEjercicio("Respuesta libre corta");
                        setModo(value);
                    }}
                    aria-label="Modo"
                >
                    <ToggleButton value="Ejercicios Tradicionales">Ejercicios Tradicionales</ToggleButton>
                    <ToggleButton value="Ejercicios dinámicos y originales">Ejercicios Dinámicos y
                        originales</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Nivel" variant="outlined" placeholder="1º Primaria"
                           onChange={(e) => setLevel(e.target.value)}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Área" variant="outlined" placeholder="Matemáticas"
                           onChange={(e) => setSubject(e.target.value)}/>
            </Grid>
            {modo === "Ejercicios Tradicionales" && <>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="tipo">Tipo de Ejercicios</InputLabel>
                        <Select labelId="tipo" fullWidth label="Tipo de Ejercicios" variant="outlined"
                                value={tipoEjercicio}
                                onChange={(e) => setTipoEjercicio(e.target.value)}>
                            <MenuItem value="Respuesta libre corta">Respuesta libre corta</MenuItem>
                            <MenuItem value="Respuesta libre larga">Respuesta libre larga de desarrollo</MenuItem>
                            <MenuItem value="Tipo Test">Tipo Test</MenuItem>
                            <MenuItem value="Verdadero / Falso">Verdadero / Falso</MenuItem>
                            <MenuItem value="Cuestionario">Cuestionario</MenuItem>
                            <MenuItem value="Examen">Examen</MenuItem>
                            <MenuItem value="Relacionar columnas">Relacionar columnas</MenuItem>
                            <MenuItem value="Arrastrar y soltar">Arrastrar y soltar</MenuItem>
                            <MenuItem value="Clasificar elementos">Clasificar elementos</MenuItem>
                            <MenuItem value="Completar frases y huecos">Completar frases y huecos</MenuItem>
                            <MenuItem value="Crucigramas o sopas de letras">Crucigramas o sopas de letras</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TextField fullWidth label="Número de preguntas" type="number" variant="outlined" defaultValue="5"
                               InputProps={{inputProps: {min: 0, max: 100}}}
                               onChange={(e) => setNumeroPreguntas(e.target.value)}/>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel control={<Checkbox onChange={(e) => setRespuestas(e.target.checked)}/>}
                                      label="Incluir respuestas"/>
                </Grid>
            </>}
            {modo === "Ejercicios dinámicos y originales" && <>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="tipo">Tipo de Ejercicios</InputLabel>
                        <Select labelId="tipo" fullWidth label="Tipo de Ejercicios" variant="outlined"
                                value={tipoEjercicio}
                                onChange={(e) => setTipoEjercicio(e.target.value)}>
                            {EJERCICIOS_ORIGINALES.map((e) => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            </>}
            <Grid item xs={12} sm={6}>
                <MuiFileInput
                    placeholder="Sube un archivo con tu temario"
                    InputProps={{
                        inputProps: {accept: ".pdf, .doc, .docx"},
                        startAdornment: <AttachFile/>
                    }}
                    error={Boolean(error)}
                    helperText={error}
                    value={file}
                    onChange={handleUpload}
                    fullWidth
                    clearIconButtonProps={{
                        title: "Quitar archivo",
                        children: <Close fontSize="small"/>
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField fullWidth label="Otras indicaciones" multiline maxRows={4} minRows={2} variant="outlined"
                           placeholder="Escribe algunas indicaciones como temática, comunidad autónoma, ideas, contexto..."
                           onChange={(e) => setOtros(e.target.value)}/>
            </Grid>
            <Grid item xs={12}>
                <LoadingButton fullWidth loading={loading} variant="contained" color="primary"
                               onClick={sendForm}>¡Generar ejercicios!</LoadingButton>
            </Grid>
            {loading && <Grid item xs={12}><Progress/></Grid>}
            {output && <Grid item xs={12}>
                <LoadingButton fullWidth loading={loading} variant="contained" color="primary"
                               onClick={saveCurrentEjercicio} startIcon={<SaveIcon/>}>Guardar en Mis
                    Ejercicios</LoadingButton>
            </Grid>}
            <Grid item xs={12}>
                {output && <>
                    <EjercicioDetails ejercicio={output}/>
                    <IAFeedback tool="Ejercicios" input={{
                        "level": level,
                        "subject": subject,
                        "otros": otros,
                        "tipo": tipoEjercicio,
                        "respuestas": respuestas,
                        "numero": numeroPreguntas,
                    }} output={output}/>
                </>}
            </Grid>
        </Grid>
    </>
}

export default EjerciciosForm;