import React, { useState, useEffect } from 'react';
import { 
    Card, 
    Grid, 
    Typography,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import { useOutletContext } from "react-router-dom";
import DeleteIconTwoTone from '@mui/icons-material/DeleteTwoTone';
import ArticleIconTwoTone from '@mui/icons-material/ArticleTwoTone';
import AIService from "../../../services/AIService";
import RubricTable from './RubricTable';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import EditIconTwoTone from '@mui/icons-material/EditTwoTone';
import { LoadingButton } from '@mui/lab';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

const RubricList = () => {
    const [rubrics, setRubrics] = useState([]);
    const [selectedRubric, setSelectedRubric] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [editingRubric, setEditingRubric] = useState(null);
    const [editedCriteria, setEditedCriteria] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [loading, setLoading] = useState(false);
    const { handleAPIError, handleSuccessMessage } = useOutletContext();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [rubricToDelete, setRubricToDelete] = useState(null);

    useEffect(() => {
        loadRubrics();
    }, []);

    const loadRubrics = () => {
        AIService.getRubrics()
            .then(response => {
                setRubrics(response.data);
            })
            .catch(error => {
                handleAPIError("Error al cargar las rúbricas");
            });
    };

    const handleDeleteClick = (event, id) => {
        event.stopPropagation();
        setRubricToDelete(id);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        AIService.deleteRubric(rubricToDelete)
            .then(() => {
                loadRubrics();
                handleSuccessMessage("Rúbrica eliminada correctamente");
            })
            .catch(error => {
                handleAPIError("Error al eliminar la rúbrica");
            })
            .finally(() => {
                setDeleteDialogOpen(false);
                setRubricToDelete(null);
            });
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setRubricToDelete(null);
    };

    const handleView = (rubric) => {
        setSelectedRubric(rubric);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        if (hasChanges) {
            if (window.confirm('Hay cambios sin guardar. ¿Estás seguro de que quieres cerrar?')) {
                resetDialog();
            }
        } else {
            resetDialog();
        }
    };

    const resetDialog = () => {
        setOpenDialog(false);
        setSelectedRubric(null);
        setEditingRubric(null);
        setEditedCriteria(null);
        setHasChanges(false);
    };

    const handleEdit = (rubric) => {
        setEditingRubric(rubric);
        setEditedCriteria(rubric.criteria);
        setSelectedRubric(rubric);
        setOpenDialog(true);
    };

    const handleSaveEdit = () => {
        setLoading(true);
        AIService.updateRubric(editingRubric.id, { criteria: editedCriteria })
            .then(() => {
                handleSuccessMessage("¡Rúbrica actualizada correctamente!");
                loadRubrics();
                resetDialog();
            })
            .catch(error => {
                handleAPIError("Error al actualizar la rúbrica");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCriteriaChange = (newCriteria) => {
        setEditedCriteria(newCriteria);
        const hasChanged = JSON.stringify(newCriteria) !== JSON.stringify(editingRubric?.criteria);
        setHasChanges(hasChanged);
    };

    const handleRowClick = (event, rubric) => {
        if (event.target.closest('.actions-cell')) {
            return;
        }
        handleView(rubric);
    };

    return (
        <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
                <Card variant="outlined" sx={{ p: 1, backgroundColor: '#FFFFFF80', textAlign: 'center' }}>
                    <Typography variant="h4">Mis Rúbricas</Typography>
                    <Typography>Aquí puedes ver todas tus rúbricas guardadas</Typography>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Button 
                    fullWidth 
                    variant="contained" 
                    color="info" 
                    href="/ia/rubricas" 
                    startIcon={<ArticleIconTwoTone/>}
                >
                    Crear nueva Rúbrica
                </Button>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Fecha de creación</TableCell>
                                <TableCell align="right" className="actions-cell">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rubrics.map((rubric) => (
                                <TableRow 
                                    key={rubric.id}
                                    onClick={(e) => handleRowClick(e, rubric)}
                                    sx={{ 
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                        },
                                    }}
                                >
                                    <TableCell>{rubric.name}</TableCell>
                                    <TableCell>
                                        {format(new Date(rubric.created_at), "d 'de' MMMM 'de' yyyy", { locale: es })}
                                    </TableCell>
                                    <TableCell align="right" className="actions-cell">
                                        <IconButton 
                                            onClick={() => handleEdit(rubric)}
                                            color="primary"
                                        >
                                            <EditIconTwoTone />
                                        </IconButton>
                                        <IconButton 
                                            onClick={(e) => handleDeleteClick(e, rubric.id)}
                                            color="error"
                                        >
                                            <DeleteIconTwoTone />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    {selectedRubric?.name}
                </DialogTitle>
                <DialogContent>
                    {selectedRubric && (
                        <RubricTable 
                            rubric={editingRubric ? editedCriteria : selectedRubric.criteria}
                            editable={!!editingRubric}
                            onChange={handleCriteriaChange}
                        />
                    )}
                    {editingRubric && (
                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12}>
                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    loading={loading}
                                    disabled={!hasChanges}
                                    onClick={handleSaveEdit}
                                >
                                    Guardar Cambios
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>

            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmar eliminación
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de que quieres eliminar esta rúbrica? Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancelar
                    </Button>
                    <LoadingButton
                        onClick={handleDeleteConfirm}
                        color="error"
                        variant="contained"
                        autoFocus
                    >
                        Eliminar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default RubricList; 