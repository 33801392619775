import React, {useState} from 'react';
import {IconButton, Typography, Box} from '@mui/material';
import ThumbUpAltTwoToneIcon from '@mui/icons-material/ThumbUpAltTwoTone';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import AIService from "../../../services/AIService";

const IAFeedback = (props) => {
    const {tool, input, output} = props;
    const [feedback, setFeedback] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleFeedback = async (isPositive) => {
        if (!isSubmitted) {
            setFeedback(isPositive);
            const input_json = JSON.stringify(input);
            const output_json = JSON.stringify(output);
            await AIService.sendFeedback(tool, input_json, output_json, isPositive);
            setIsSubmitted(true);
        }
    };

    return (
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box sx={{display: 'flex', gap: 6}}>
                    <IconButton
                        onClick={() => handleFeedback(true)}
                        disabled={isSubmitted && feedback !== true}
                        sx={{
                            color: feedback === true ? 'success.main' : 'action.active',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                            '&.Mui-disabled': {
                                color: 'action.disabled',
                            },
                        }}
                        aria-label="Thumbs up"
                    >
                        <ThumbUpAltTwoToneIcon/>
                    </IconButton>

                    <IconButton
                        onClick={() => handleFeedback(false)}
                        disabled={isSubmitted && feedback !== false}
                        sx={{
                            color: feedback === false ? 'error.main' : 'action.active',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                            '&.Mui-disabled': {
                                color: 'action.disabled',
                            },
                        }}
                        aria-label="Thumbs down"
                    >
                        <ThumbDownAltTwoToneIcon/>
                    </IconButton>
                </Box>

                {isSubmitted && (
                    <Typography variant="body2" color="text.secondary">
                        ¡Gracias por tu feedback! Lo usaremos para mejorar.
                    </Typography>
                )}
            </Box>
    );
};

export default IAFeedback;