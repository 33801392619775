import React, {useState} from "react";
import {Tab, Tabs, Typography} from "@mui/material";
import MainCard from "../genericui/cards/MainCard";
import ErrorMessage from "../genericui/feedback/ErrorMessage";
import {EventNoteTwoTone as EventNote, LockTwoTone as Lock, PersonTwoTone as Person} from "@mui/icons-material";
import PasswordUpdate from "../PasswordUpdate";
import ProfileSettingsForm from "./ProfileSettingsForm";
import Box from "@mui/material/Box";
import SuccessMessage from "../genericui/feedback/SuccessMessage";
import CalendarSettingsForm from "./CalendarSettingsForm";
import Subscription from "../subscription";
import {PaymentsTwoTone as PaymentsIcon} from '@mui/icons-material';
import logo from "../logo192.png";
import {FloatingWhatsApp} from "react-floating-whatsapp";

export default function SettingsLayout(props) {
    const [currentTab, setCurrentTab] = useState(0);
    const [message, setMessage] = useState("");
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);
    const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

    function handleTabChange(event, newValue) {
        setCurrentTab(newValue);
    }

    function SettingsElement() {
        switch (currentTab) {
            case 0:
                return <ProfileSettingsForm handleAPIError={handleAPIError} showSuccessMessage={showSuccessMessage}/>
            case 1:
                return <PasswordUpdate handleAPIError={handleAPIError} showSuccessMessage={showSuccessMessage}/>
            case 2:
                return <CalendarSettingsForm handleAPIError={handleAPIError} showSuccessMessage={showSuccessMessage}/>
            case 3:
                return <Subscription handleAPIError={handleAPIError} showSuccessMessage={showSuccessMessage}/>
            default:
                return <Typography>Ha ocurrido un error. Por favor, recarga la página.</Typography>
        }
    }

    function showSuccessMessage(message) {
        setIsSuccessMessageVisible(true);
        setMessage(message);
    }

    function handleAPIError(message) {
        setMessage(message);
        setIsErrorMessageVisible(true);
    }

    return <MainCard title="⚙️ Ajustes" sx={{minHeight: "600px"}}>
        {isErrorMessageVisible &&
            <ErrorMessage disableErrorMessage={() => setIsErrorMessageVisible(false)} message={message}/>}
        {isSuccessMessageVisible &&
            <SuccessMessage disableSuccessMessage={() => setIsSuccessMessageVisible(false)} message={message}/>}
        <Tabs value={currentTab} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
            <Tab icon={<Person/>} iconPosition="start" label="Perfil" sx={{minHeight: "48px"}}/>
            <Tab icon={<Lock/>} iconPosition="start" label="Contraseña" sx={{minHeight: "48px"}}/>
            <Tab icon={<EventNote/>} iconPosition="start" label="Agenda" sx={{minHeight: "48px"}}/>
            <Tab icon={<PaymentsIcon/>} iconPosition="start" label="¡Suscríbete!" sx={{minHeight: "48px"}}/>
        </Tabs>
        <Box sx={{mt: 2}}>
            <SettingsElement/>
            <FloatingWhatsApp buttonStyle={{marginBottom: 35, height: 45, width: 45, animation: null}}
                                      chatboxHeight={400} avatar={logo} phoneNumber="+34661024314"
                                      accountName="Francis de 4Docentes" statusMessage="Disponible"
                                      chatMessage="¡Hola! Soy Francis. Déjame un mensaje si necesitas cualquier cosa. ¡Recuerda que no soy una inteligencia artificial!"
                                      placeholder="Escribe un mensaje..." messageDelay={0} allowClickAway allowEsc
                                      notification={false}/>
        </Box>
    </MainCard>
}