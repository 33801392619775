import React, {useState, useContext, useEffect} from 'react';
import {
    AppBar,
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    Tooltip,
    styled,
    useTheme,
    ThemeProvider,
    createTheme, Container, Paper, Collapse, Divider,
} from '@mui/material';
import {
    MenuTwoTone as MenuIcon,
    LightModeTwoTone as LightModeIcon,
    DarkModeTwoTone as DarkModeIcon,
    SettingsTwoTone as SettingsIcon,
    ChevronLeftTwoTone as ChevronLeftIcon,
    ChevronRightTwoTone as ChevronRightIcon,
    NoteAltTwoTone,
    AutoFixHighTwoTone,
    LocalLibraryTwoTone,
    EditCalendarTwoTone, SchoolTwoTone, DashboardTwoTone, StickyNote2TwoTone, ExpandLess, ExpandMore, ChatTwoTone,
} from '@mui/icons-material';
import {deepPurple} from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import ProfilePopup from "./profile_popup/ProfilePopup";
import BottomNavigationMenu from "../BottomNavigationMenu";
import SubscriptionMenuItem from "./SubscriptionMenuItem";
import ContactUsMenuItem from "./ContactUsMenuItem";

// Create ColorModeContext
const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
});

const drawerWidthExpanded = 250;
const drawerWidthCollapsed = 100;

// Styled components
const StyledAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'collapsed',
})(({theme, open, collapsed}) => ({
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const StyledDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'collapsed',
})(({theme, collapsed}) => ({
    width: collapsed ? drawerWidthCollapsed : drawerWidthExpanded,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
        width: collapsed ? drawerWidthCollapsed : drawerWidthExpanded,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        border: 'none',
        backgroundColor: theme.palette.background.paper,
        borderRight: `1px solid ${theme.palette.divider}`,
        overflowX: 'hidden',
    },
}));

const StyledListItemButton = styled(ListItemButton)(({theme, active}) => ({
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(0.5),
    minHeight: 48,
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
    },
    '&.active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiSvgIcon-root': {
            color: "white",
        },
    },
}));

const Navigation = (props) => {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [activeItem, setActiveItem] = useState('');
    const [itemsColapsed, setItemsCollapsed] = useState({
        'Inteligencia Artificial': true,
        'Cuaderno Docente': true,
    });

    useEffect(() => {
        const path = document.location.pathname.toString();
        if (path === "/ia/" || path === "/ia") {
            setActiveItem('Catálogo de IAs');
        } else if (path.includes("sdas")) {
            setActiveItem('Situaciones de aprendizaje');
        } else if (path.includes("chat")) {
            setActiveItem('Asistente virtual');
        } else if (path.includes("clases")) {
            setActiveItem('Clases');
        } else if (path.includes("agenda")) {
            setActiveItem('Agenda');
        } else if (path.includes("post-its")) {
            setActiveItem('Anotaciones');
        } else if (path.includes("ajustes")) {
            setActiveItem("Ajustes");
        } else {
            setActiveItem('Catálogo de IAs');
        }
    }, []);


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const toggleDrawer = () => {
        setCollapsed(!collapsed);
    };

    const mainNavItems = [
        {text: 'Inteligencia Artificial', icon: <AutoFixHighTwoTone/>},
        {text: 'Cuaderno Docente', icon: <NoteAltTwoTone/>},
    ];

    const secondaryNavItems = {
        'Inteligencia Artificial': [
            {text: 'Catálogo de IAs', icon: <DashboardTwoTone/>, to: '/ia/'},
            {text: 'Situaciones de aprendizaje', icon: <LocalLibraryTwoTone/>, to: '/ia/sdas/'},
            {text: 'Asistente virtual', icon: <ChatTwoTone/>, to: '/ia/chat/'},
        ],
        'Cuaderno Docente': [
            {text: 'Clases', icon: <SchoolTwoTone/>, to: '/clases/'},
            {text: 'Agenda', icon: <EditCalendarTwoTone/>, to: '/agenda/'},
            {text: 'Anotaciones', icon: <StickyNote2TwoTone/>, to: '/post-its/'},
        ],
    };

    const logo = require('./logo192.png');

    const drawer = (
            <>
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                        <Box
                            component="img"
                            src={logo}
                            alt="4Docentes"
                            sx={{height: 32, width: 32}}
                        />
                        {!collapsed && (
                            <Typography variant="h6" noWrap component="div" fontWeight="bold">
                                4Docentes
                            </Typography>
                        )}
                    </Box>
                    <IconButton onClick={toggleDrawer}>
                        {collapsed ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </Toolbar>

                <Box sx={{p: 0}} key={"list"}>
                    <List sx={{p: 0, width: '100%'}}>
                        {mainNavItems.map((item) => (
                            <React.Fragment key={item.text}>
                                <Divider key={item.text + 'divider'}/>
                                <ListItem key={item.text} disablePadding>
                                    <StyledListItemButton
                                        className={activeItem === item.text ? 'active' : ''}
                                        onClick={() => setItemsCollapsed({
                                            ...itemsColapsed,
                                            [item.text]: !itemsColapsed[item.text]
                                        })}
                                        sx={{
                                            justifyContent: collapsed ? 'center' : 'flex-start',
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                color: activeItem === item.text ? 'inherit' : 'text.secondary',
                                                minWidth: collapsed ? 0 : 40,
                                                mr: collapsed ? 0 : 2,
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {item.icon}
                                        </ListItemIcon>
                                        {!collapsed && (
                                            <><ListItemText
                                                primary={item.text}
                                                primaryTypographyProps={{
                                                    style: {whiteSpace: "normal"},
                                                    fontSize: '0.875rem',
                                                    fontWeight: activeItem === item.text ? 600 : 400
                                                }}
                                            />
                                                {<IconButton
                                                    color="inherit"
                                                >
                                                    {itemsColapsed[item.text] ? <ExpandLess/> : <ExpandMore/>}
                                                </IconButton>}
                                            </>
                                        )}
                                    </StyledListItemButton>
                                </ListItem>
                                {<Collapse in={itemsColapsed[item.text]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {secondaryNavItems[item.text].map((secondaryItem) =>
                                            (<StyledListItemButton
                                                className={activeItem === secondaryItem.text ? 'active' : ''}
                                                to={secondaryItem.to}
                                                key={secondaryItem.text}
                                                sx={{
                                                    justifyContent: collapsed ? 'center' : 'flex-start',
                                                    pl: 6,
                                                    mb: 0.5,
                                                }}
                                            >
                                                <ListItemIcon>
                                                    {secondaryItem.icon}
                                                </ListItemIcon>
                                                {!collapsed && <ListItemText sx={{pr: 1}} primary={secondaryItem.text}
                                                                             primaryTypographyProps={{
                                                                                 style: {whiteSpace: "normal"},
                                                                                 fontSize: '0.850rem',
                                                                                 fontWeight: activeItem === secondaryItem.text ? 600 : 400
                                                                             }}/>}
                                            </StyledListItemButton>))
                                        }
                                    </List>
                                </Collapse>}
                                <Divider key={item.text + 'dividersecond'}/>
                            </React.Fragment>
                        ))}
                    </List></Box>
                <Box sx={{flexGrow: 1}}/>
                {!collapsed && <Box sx={{flex: 1}}><SubscriptionMenuItem/></Box>}
                <Box sx={{flex: 1}}><ContactUsMenuItem collapsed={collapsed}/></Box>
            </>
        )
    ;

    return (
        <Box sx={{display: 'flex'}}>
            <StyledAppBar position="fixed" open={true} collapsed={collapsed}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                        {/*<Tooltip title={theme.palette.mode === 'dark' ? 'Modo Claro' : 'Modo Oscuro'}>
                            <IconButton size="small" onClick={colorMode.toggleColorMode}>
                                {theme.palette.mode === 'dark' ? <LightModeIcon/> : <DarkModeIcon/>}
                            </IconButton>
                        </Tooltip>*/
                            /* TODO: habilitar dark mode */}
                        <Tooltip title="Ajustes">
                            <IconButton size="small" aria-label="Ajustes" href='/ajustes/' sx={{
                                '&.active': {
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    '& .MuiListItemIcon-root': {
                                        color: theme.palette.primary.contrastText,
                                    },
                                },
                            }}
                                        className={activeItem === 'Ajustes' ? 'active' : ''}
                            >
                                <SettingsIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </StyledAppBar>

            <Box component="nav"
                 sx={{width: {sm: collapsed ? drawerWidthCollapsed : drawerWidthExpanded}, flexShrink: {sm: 0}}}>
                <StyledDrawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    collapsed={collapsed}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                    }}
                >
                    {drawer}
                </StyledDrawer>
                <StyledDrawer
                    variant="permanent"
                    sx={{display: {xs: 'none', sm: 'block'}}}
                    open
                    collapsed={collapsed}
                >
                    {drawer}
                </StyledDrawer>
            </Box>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    pt: {lg: 11, sm: 11, xs: 8},
                    width: {sm: `calc(100% - ${collapsed ? drawerWidthCollapsed : drawerWidthExpanded}px)`},
                    transition: (theme) => theme.transitions.create(['width', 'margin'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    height: '100vh',
                    color: 'white',
                    overflow: 'auto',
                    backgroundColor: '#f4f5fa',
                }}
            >
                <Container
                    sx={{mb: 2, pt: 1.5, pb: {xs: 8, sm: 1.5}}}
                    maxWidth="lg"
                >
                    {props.children}
                </Container>
                <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000, display: {sm: 'none'}}}
                       elevation={2}>
                    <BottomNavigationMenu/>
                </Paper>
            </Box>
        </Box>
    );
};

// Theme wrapper component
const NavigationWithTheme = ({children}) => {
    const [mode, setMode] = useState('light');

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: mode,
                    primary: {
                        name: 'primary-1',
                        light: '#A379FF',
                        main: '#8C57FF',
                        dark: '#7E4EE6',
                    },
                    ia: {
                        header: '#f3f3f3'
                    },
                    evaluation: {
                        background: deepPurple[200],
                        headerLightBackground: deepPurple[200],
                        headerBackground: deepPurple[400],
                    },
                },
                components: {
                    MuiPopper: {
                        styleOverrides: {
                            root: {
                                zIndex: 1300
                            }
                        }
                    }
                }
            }),
        [mode],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Navigation>
                    {children}
                    <ProfilePopup/>
                </Navigation>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default NavigationWithTheme;