import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import {SchoolTwoTone as School, AutoFixHighTwoTone as AutoFixHigh} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

export default function BottomNavigationMenu(props) {
    const [selectedModule, setSelectedModule] = useState(0);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        if (isFirstLoad) {
            const pathList = document.location.pathname.toString().split('/');
            let currentPath = "";
            if (pathList.length > 1) {
                currentPath = pathList[1];
            }
            if (currentPath.includes("clases") || currentPath.includes("agenda") || currentPath.includes("post-its")) {
                setSelectedModule(1);
            } else {
                setSelectedModule(0);
            }
            setIsFirstLoad(false);
        }
    }, [isFirstLoad])

    return <BottomNavigation
        elevation={3}
        showLabels
        value={selectedModule}
        onChange={(event, newValue) => {
            setSelectedModule(newValue);
        }}
    >
        <BottomNavigationAction component={Link} to="/ia/" label="Inteligencia Artificial" icon={<AutoFixHigh/>}/>
        <BottomNavigationAction component={Link} to="/clases/" label="Cuaderno Docente" icon={<School/>}/>
    </BottomNavigation>
}