import React, {useEffect, useState} from "react";
import {Autocomplete, Card, Grid} from "@mui/material";
import {useOutletContext} from "react-router-dom";
import AIService from "../../../services/AIService";
import TextField from "@mui/material/TextField";
import {Alert, LoadingButton} from "@mui/lab";
import {MuiFileInput} from 'mui-file-input';
import {AttachFile, Close} from "@mui/icons-material";
import SubscriptionPopup from "../../subscription/popup";
import SettingsService from "../../../services/SettingsService";
import CheckerDetails from "./CheckerDetails";
import {COMUNIDADES, LEVELS} from "../../classrooms/utils";
import SubjectService from "../../../services/SubjectService";
import Typography from "@mui/material/Typography";
import IAFeedback from "../feedback/IAFeedback";
import Progress from "../../genericui/feedback/Progress";

const CheckerForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [output, setOutput] = useState("");
    const {handleAPIError} = useOutletContext();
    const [region, setRegion] = useState(COMUNIDADES[0]);
    const [level, setLevel] = useState("");
    const [subjects, setSubjects] = useState([]);
    const [subject, setSubject] = useState("");
    const [subjectId, setSubjectId] = useState("");
    const [ejercicio, setEjercicio] = useState("");
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
        if (region && level) {
            SubjectService.getLomloeSubjectsByRegionAndLevel(region.id, level.id).then(response => setSubjects(response.data))
                .catch(() => setSubjects([]));
        }
    }, [region, level]);


    function sendForm(event) {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('level', level.name);
        formData.append('subject', subject);
        formData.append('subjectId', subjectId);

        if (!file) {
            formData.append('ejercicio', ejercicio)
        } else {
            formData.append('file', file);
        }

        generateContent(formData);
    }

    function generateContent(formData) {
        SettingsService.getProfileInfo().then(response => {
            if (!response.data.is_subscription_active) {
                setIsPopupOpen(true);
            }
        })
        AIService.checkActivity(formData).then(response => {
                setOutput(response.data.text);
                setLoading(false);
            },
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        ).catch(error => {
            handleAPIError(error);
            setLoading(false);
        }).finally(() => setLoading(false));
    }

    function handleUpload(value) {
        if (value?.size > 10 * 1024 * 1024) {
            setError("El archivo es demasiado grande. Tamaño maximo: 10 MB");
        } else {
            setError("");
            setFile(value);
        }
    }

    return <>
        {isPopupOpen && <SubscriptionPopup onClose={() => setIsPopupOpen(false)}/>}
        <form onSubmit={sendForm}>
        <Grid container spacing={2} sx={{p: 2, display: 'flex'}}>
            <Grid item xs={12}>
                <Card variant="outlined"
                      sx={{p: 1, backgroundColor: '#FFFFFF80', color: 'text.primary', textAlign: 'center'}}>
                    <Typography variant="h4">Comprobador de currículo LOMLOE</Typography>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Alert severity="info">Introduce los detalles de una actividad que hayas pensado, o sube un documento que la contenga, y nuestra IA te dirá qué criterios LOMLOE están relacionados con dicha actividad.</Alert>
            </Grid>
            <Grid item xs={12} sm={6}>
                    <Autocomplete
                        id="lomloe_region"
                        options={COMUNIDADES}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => setRegion(value)}
                        renderInput={(params) => <TextField fullWidth {...params} required label="Comunidad Autónoma"
                                                            variant="outlined"/>}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        id="lomloe_level"
                        options={LEVELS}
                        disabled={!region}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => setLevel(value)}
                        renderInput={(params) => <TextField fullWidth {...params} required label="Nivel"
                                                            variant="outlined"/>}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        id="lomloe_subject"
                        options={subjects}
                        disabled={!subjects || subjects.length === 0}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                            setSubjectId(value.id);
                            setSubject(value.name);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} required label="Asignatura"
                                                            variant="outlined"/>}
                    />
                </Grid>
            <Grid item xs={12} sm={6}>
                <MuiFileInput
                    placeholder="Sube un archivo con tu temario"
                    InputProps={{
                        inputProps: {accept: ".pdf, .doc, .docx"},
                        startAdornment: <AttachFile/>
                    }}
                    error={Boolean(error)}
                    helperText={error}
                    value={file}
                    onChange={handleUpload}
                    fullWidth
                    clearIconButtonProps={{
                        title: "Quitar archivo",
                        children: <Close fontSize="small"/>
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField fullWidth label="Descripción de la actividad" multiline maxRows={4} minRows={2} variant="outlined"
                           placeholder="En lugar de subir un archivo, puedes describir tu actividad aquí"
                           onChange={(e) => setEjercicio(e.target.value)}/>
            </Grid>
            <Grid item xs={12}>
                <LoadingButton fullWidth loading={loading} variant="contained" color="primary"
                               type="submit">¡Comprobar actividad!</LoadingButton>
            </Grid>
            {loading && <Grid item xs={12}><Progress/></Grid>}
            <Grid item xs={12}>
                {output && <>
                    <CheckerDetails checker={output}/>
                    <IAFeedback tool="Checker" input={{
                        region,
                        level,
                        subjectId,
                        ejercicio,
                    }} output={output}/>
                </>}
            </Grid>
        </Grid>
        </form>
    </>
}

export default CheckerForm;